import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [cu_sel,set_sel]=useState(0);
  const params = new URLSearchParams(window.location.search);
  const mch_id = params.get('mch_id');
  const mch_mold = params.get('mch_mold');
  const [code,set_code]=useState('');



  async function send_data(data,callback)
  {
      const cu_url='https://game.anyidata.com/main.php';
      await fetch(cu_url,{
              method:'POST',
              headers:{
                  'content-type': 'application/json',
                  'Accept-Language':'zh-CN'
              },
              body:JSON.stringify(data)
          })
          .then(res=>res.json())
          .then(data=>{callback(data)})
          .finally()     
  }
  function Pay_list(props)
  {
    var my_class='pay_list';
    if(props.tehui=='true')
    {
      my_class=my_class+' tehui';
    }
    if(cu_sel==props.money)
    {
      my_class=my_class+' pay_list_sel';
    }

    return(
        <div onClick={()=>{set_sel(props.money)}} className={my_class}>
          <span>￥{props.money}元<br></br><span className='money_txt'>{props.time}</span></span> 
        </div>
    )
  }

  function run_pay(money,mch_id)
  {
    if(mch_id=='' || mch_id==null)
    {
      alert('错误，请重新扫码进入');
      return;
    }
    window.location.href='https://game.anyidata.com/wxpay/pay/jsapi.php?money='+money+'&mch_id='+mch_id+'&mch_mold='+mch_mold;

  }

  function post_data()
  {
    let par={"mch_id":mch_id,"code":code,"mch_mold":mch_mold};
    send_data(par,(e)=>{
       alert(e.msg);
    })
  }
  return (
    <div className='main_back'>
        <div className='logo'></div>
        <span className='mch_txt'>设备码：{mch_id}</span>
        <div className='money_list'>
            <Pay_list money="1" time="60分钟" />
            <Pay_list money="2" time="120分钟" />
            <Pay_list money="3" time="180分钟" />
            <Pay_list money="5" tehui="true" time="360分钟" />
          {/* <Pay_list money="19" time="20分钟" />
          <Pay_list money="29" time="30分钟"/>
          <Pay_list money="46" tehui="true" time="60分钟"/>
          <Pay_list money="80" time="120分钟"/> */}
        </div>
        {cu_sel>0?<div onClick={()=>{run_pay(cu_sel,mch_id)}} className='payok'>支 付</div>:''}
        
        <div className='usequan'>
          <span>使用兑换码：</span><br></br>
          <input value={code} onChange={(e)=>set_code(e.target.value)} className='input_txt' />
          <div onClick={()=>post_data(code)} className='quan_btn'>确定</div>
        </div>
    </div>
    
  );
}

export default App;
